import { getWeek } from '@sunmedia/sun-ui/src/utils';
import { TODAY } from '@/utils/dateTime/today';
import { addDays } from '@/utils/dateTime/addDays';

const CURRENT_WEEK = getWeek(TODAY);
const LAST_WEEK = getWeek(addDays(TODAY, -7));

const CURRENT_WEEK_LABEL = 'This week';
const LAST_WEEK_LABEL = 'Last week';

export { CURRENT_WEEK, CURRENT_WEEK_LABEL, LAST_WEEK, LAST_WEEK_LABEL };

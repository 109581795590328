var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-row justify-between border relative border-gray-300 h-10 bg-white shadow rounded",
      attrs: { id: "date-picker-content" },
    },
    [
      _c("vue-date-picker", {
        ref: "menu",
        class: [_vm.classDatePicker],
        attrs: {
          id: _vm.id,
          value: _vm.currentValue,
          format: _vm.format,
          "format-header": _vm.formatHeader,
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
          range: _vm.range,
          placeholder: _vm.placeholder,
          "range-input-text": _vm.rangeInputText,
          "no-header": _vm.noHeader,
          "no-calendar-icon": _vm.noCalendarIcon,
          locale: _vm.locale,
          color: _vm.color,
          "visible-years-number": _vm.visibleYearsNumber,
          disabled: _vm.disabled,
        },
        on: {
          onOpen: _vm.onOpen,
          onChange: _vm.onChange,
          onClose: _vm.onClose,
          onDestroy: _vm.onDestroy,
        },
        model: {
          value: _vm.currentValue,
          callback: function ($$v) {
            _vm.currentValue = $$v
          },
          expression: "currentValue",
        },
      }),
      _vm.showDateList
        ? _c(
            "sun-button",
            {
              staticClass:
                "hover:bg-gray-100 focus:bg-gray-100 custom-button shadow-none focus:shadow-none bg-white absolute right-0 h-full",
              attrs: {
                id: `filter_date${_vm.id}`,
                color: "transparent",
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  _vm.seeOptions = !_vm.seeOptions
                },
              },
            },
            [
              _c("sun-horizontal-bar-svg", {
                staticClass: "w-5 text-gray-700",
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.seeOptions
            ? _c("sun-list-dates", {
                staticClass:
                  "absolute date-list-container right-0 z-40 overflow-y-auto shadow rounded",
                attrs: { dates: _vm.dateList },
                on: { select: _vm.setDefaultDates },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.seeOptions
        ? _c("div", {
            staticClass: "fixed inset-0 w-screen h-screen z-30 bg-transparent",
            on: {
              click: function ($event) {
                _vm.seeOptions = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
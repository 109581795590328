/**
 * Returns an object with the start and end date for the desired month. By default, monthsFrom is 0, so current month
 * is selected. If we use -1 instead, we will get values for last month.
 *
 * @param {Date} date
 * @param {number} monthsFrom
 * @returns {{endDate: Date, startDate: Date}}
 */

export function getMonthInterval(date = new Date(), monthsFrom = 0) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const startDate = new Date(year, month + monthsFrom, 1);
  const endDate = new Date(year, month + monthsFrom + 1, 0);

  return { startDate, endDate };
}

import CONFIG from './config';

const datePickerV2Props = {
  props: {
    value: {
      type: [String, Object, Number, Date],
      default: () => CONFIG.date,
    },
    name: {
      type: String,
      default: () => CONFIG.name,
    },
    allowedDates: {
      type: Function,
      default: () => CONFIG.allowedDates,
    },
    allowedOverflow: {
      type: Boolean,
      default: () => CONFIG.allowedOverflow,
    },
    attach: {
      default: () => CONFIG.attach,
    },
    buttonCancel: {
      type: String,
      default: () => CONFIG.buttonCancel,
    },
    buttonValidate: {
      type: String,
      default: () => CONFIG.buttonValidate,
    },
    validate: {
      type: Boolean,
      default: () => CONFIG.validate,
    },
    clearable: {
      type: Boolean,
      default: () => CONFIG.clearable,
    },
    color: {
      type: String,
      default: () => CONFIG.color,
    },
    contentClass: {
      type: String,
      default: () => CONFIG.contentClass,
    },
    disabled: {
      type: Boolean,
      default: () => CONFIG.disabled,
    },
    format: {
      type: String,
      default: () => CONFIG.format,
    },
    formatHeader: {
      type: String,
      default: () => CONFIG.formatHeader,
    },
    fullscreenMobile: {
      type: Boolean,
      default: () => CONFIG.fullscreenMobile,
    },
    mobileBreakpoint: {
      type: [String, Number],
      default: () => CONFIG.mobileBreakpoint,
    },
    inline: {
      type: Boolean,
      default: () => CONFIG.inline,
    },
    locale: {
      type: Object,
      default: () => CONFIG.locale,
    },
    minDate: {
      type: [String, Number, Date],
      default: () => CONFIG.minDate,
    },
    maxDate: {
      type: [String, Number, Date],
      default: () => CONFIG.maxDate,
    },
    noCalendarIcon: {
      type: Boolean,
      default: () => CONFIG.noCalendarIcon,
    },
    noHeader: {
      type: Boolean,
      default: () => CONFIG.noHeader,
    },
    origin: {
      type: String,
      default: () => CONFIG.origin,
    },
    placeholder: {
      type: String,
      default: () => CONFIG.placeholder,
    },
    range: {
      type: Boolean,
      default: () => CONFIG.range,
    },
    rangeHeaderText: {
      type: String,
      default: () => CONFIG.rangeHeaderText,
    },
    rangeInputText: {
      type: String,
      default: () => CONFIG.rangeInputText,
    },
    rangePresets: {
      type: Array,
      default: () => CONFIG.rangePresets,
    },
    rtl: {
      type: Boolean,
      default: () => CONFIG.rtl,
    },
    tabindex: {
      type: Number,
      default: () => CONFIG.tabindex,
    },
    type: {
      type: String,
      default: () => CONFIG.type,
    },
    visible: {
      type: Boolean,
      default: () => CONFIG.visible,
    },
    visibleYearsNumber: {
      type: Number,
      default: () => CONFIG.visibleYearsNumber,
    },
    zIndex: {
      type: Number,
      default: () => CONFIG.zIndex,
    },
  },
};

export default datePickerV2Props;

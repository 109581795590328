var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return isNaN(_vm.value) || _vm.value === null
    ? _c("span", { staticClass: "block w-full text-right" }, [_vm._v(" N/A ")])
    : _c("span", { staticClass: "block w-full text-right" }, [
        _vm._v(_vm._s(_vm._f("currency")(_vm.value, _vm.currency, "de-DE"))),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <span v-if="!isValidDate" class="truncate w-full text-center"> - </span>
    <date-tooltip v-else :date="value" class="block w-full text-center"></date-tooltip>
  </div>
</template>

<script>
import DateTooltip from '../DateTooltip/DateTooltip.vue';
import { isValidStringDate } from '@/utils/validation/isValidStringDate';

export default {
  name: 'DateCell',
  components: {
    DateTooltip,
  },
  props: {
    value: {
      type: [String, null],
      default: () => '',
    },
  },
  computed: {
    isValidDate() {
      return isValidStringDate(this.value);
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value === null || isNaN(_vm.value)
    ? _c("span", { staticClass: "block w-full text-right" }, [_vm._v("N/A")])
    : _c("span", { staticClass: "block w-full text-right" }, [
        _vm._v(
          _vm._s(
            _vm._f("percentage")(
              _vm.value,
              "-",
              "es-ES",
              _vm.maximumFractionDigits
            )
          )
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { getMonth } from '@sunmedia/sun-ui/src/utils';
import { TODAY } from '@/utils/dateTime/today';
import { getMonthInterval } from '@/utils/dateTime/getMonthInterval';

const CURRENT_MONTH = getMonth(TODAY);
const LAST_MONTH = getMonthInterval(TODAY, -1);

const CURRENT_MONTH_LABEL = 'This month';
const LAST_MONTH_LABEL = 'Last month';

export { CURRENT_MONTH, CURRENT_MONTH_LABEL, LAST_MONTH, LAST_MONTH_LABEL };

<template>
  <span v-if="isNaN(value) || value === null" class="block w-full text-right"> N/A </span>
  <span v-else class="block w-full text-right">{{ value | currency(currency, 'de-DE') }}</span>
</template>

<script>
export default {
  name: 'CurrencyCell',
  props: {
    value: {
      type: [Number, String],
      default: () => 0,
    },
    currency: {
      type: String,
      default: () => '~',
    },
  },
};
</script>

<style scoped></style>

import { api } from '..';
import { SALESFORCE_PRODUCT_RESOURCE } from '@/services/keys';

/**
 *
 * @param id
 * @param product
 * @returns {Promise<AxiosResponse<*>>}
 */
export async function updateProduct(id, product = {}) {
  const partialUrl = api.createUrl({
    [SALESFORCE_PRODUCT_RESOURCE]: id,
  });

  return await api.update(partialUrl, product);
}

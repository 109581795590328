import { addDays } from '@/utils/dateTime/addDays';
import { TODAY } from '@/utils/dateTime/today';
import { CURRENT_WEEK, CURRENT_WEEK_LABEL, LAST_WEEK, LAST_WEEK_LABEL } from '@/utils/dateTime/week';
import { CURRENT_MONTH, CURRENT_MONTH_LABEL, LAST_MONTH, LAST_MONTH_LABEL } from '@/utils/dateTime/month';

const rawDateList = [
  {
    title: 'Today',
    startDate: TODAY,
    endDate: TODAY,
  },
  {
    title: 'Yesterday',
    startDate: addDays(TODAY, -1),
    endDate: addDays(TODAY, -1),
  },
  {
    title: 'Last 7 days',
    startDate: addDays(TODAY, -7),
    endDate: TODAY,
  },
  {
    title: 'Last 14 days',
    startDate: addDays(TODAY, -14),
    endDate: TODAY,
  },
  {
    title: 'Last 30 days',
    startDate: addDays(TODAY, -30),
    endDate: TODAY,
  },
  {
    title: CURRENT_WEEK_LABEL,
    startDate: CURRENT_WEEK.startDate,
    endDate: TODAY,
  },
  {
    title: CURRENT_MONTH_LABEL,
    startDate: CURRENT_MONTH.startDate,
    endDate: TODAY,
  },
  {
    title: LAST_WEEK_LABEL,
    startDate: LAST_WEEK.startDate,
    endDate: LAST_WEEK.endDate,
  },
  {
    title: LAST_MONTH_LABEL,
    startDate: LAST_MONTH.startDate,
    endDate: LAST_MONTH.endDate,
  },
  {
    title: 'Custom',
    startDate: null,
    endDate: null,
  },
];

const MONDAY = 1;
const dateListWithToday = rawDateList.filter(option => {
  if (option.title === CURRENT_WEEK_LABEL) {
    // Not needed when today is the first day of the week
    return TODAY.getDay() !== MONDAY;
  }
  if (option.title === CURRENT_MONTH_LABEL) {
    // Not needed when today is the first day of the month
    return TODAY.getDate() !== 1;
  }
  return !!option;
});

export { dateListWithToday };

<template>
  <span v-if="value === null || isNaN(value)" class="block w-full text-right">N/A</span>
  <span v-else class="block w-full text-right">{{ value | percentage('-', 'es-ES', maximumFractionDigits) }}</span>
</template>

<script>
export default {
  name: 'PercentageCell',
  props: {
    value: {
      type: [Number, String],
      default: () => 0,
    },
    maximumFractionDigits: {
      type: [Number, String],
      default: () => 2,
      validator(value) {
        return !isNaN(Number.parseInt(value));
      },
    },
  },
};
</script>

<style scoped></style>

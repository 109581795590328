<template>
  <div
    id="date-picker-content"
    class="flex flex-row justify-between border relative border-gray-300 h-10 bg-white shadow rounded"
  >
    <vue-date-picker
      :id="id"
      ref="menu"
      v-model="currentValue"
      :value="currentValue"
      :class="[classDatePicker]"
      :format="format"
      :format-header="formatHeader"
      :min-date="minDate"
      :max-date="maxDate"
      :range="range"
      :placeholder="placeholder"
      :range-input-text="rangeInputText"
      :no-header="noHeader"
      :no-calendar-icon="noCalendarIcon"
      :locale="locale"
      :color="color"
      :visible-years-number="visibleYearsNumber"
      :disabled="disabled"
      @onOpen="onOpen"
      @onChange="onChange"
      @onClose="onClose"
      @onDestroy="onDestroy"
    />
    <sun-button
      v-if="showDateList"
      :id="`filter_date${id}`"
      color="transparent"
      class="hover:bg-gray-100 focus:bg-gray-100 custom-button shadow-none focus:shadow-none bg-white absolute right-0 h-full"
      :disabled="disabled"
      @click="seeOptions = !seeOptions"
    >
      <sun-horizontal-bar-svg class="w-5 text-gray-700" />
    </sun-button>
    <transition name="fade">
      <sun-list-dates
        v-if="seeOptions"
        class="absolute date-list-container right-0 z-40 overflow-y-auto shadow rounded"
        :dates="dateList"
        @select="setDefaultDates"
      />
    </transition>
    <div
      v-if="seeOptions"
      class="fixed inset-0 w-screen h-screen z-30 bg-transparent"
      @click="seeOptions = false"
    ></div>
  </div>
</template>

<script>
import defaultProps from '@sunmedia/sun-ui/src/mixins/defaultProps';
import SunHorizontalBarSvg from '@sunmedia/sun-ui/src/components/icons/SunHorizontalBarSvg.vue';
import SunListDates from '@sunmedia/sun-ui/src/components/atoms/SunListDates/SunListDates.vue';
import CONFIG from './config';
import datePickerProps from '@/mixins/datePickerV2/datePickerV2Props';
import { stringToDate } from '@/filters/dateFilters';

export default {
  name: 'SunDatePickerV2',
  components: { SunHorizontalBarSvg, SunListDates },
  mixins: [defaultProps, datePickerProps],
  props: {
    value: {
      type: [String, Object, Number, Date],
      default: () => CONFIG.date,
    },
    dateList: {
      type: Array,
      default: () => undefined,
    },
    showDateList: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    currentValue: CONFIG.date,
    seeOptions: false,
  }),
  computed: {
    classDatePicker() {
      return [
        CONFIG.class,
        { [`${CONFIG.classNotDisabled}`]: !this.disabled },
        { [`${CONFIG.classDisabled}`]: this.disabled },
      ];
    },
  },
  watch: {
    value() {
      this.initValue();
    },
  },
  created() {
    this.initValue();
  },
  methods: {
    initValue() {
      if (this.value) {
        let start = new Date();
        let end = new Date();
        if (this.value.startDate && this.value.endDate) {
          start = this.value.startDate;
          end = this.value.endDate;
        }
        if (this.value.start && this.value.end) {
          start = this.value.start;
          end = this.value.end;
        }
        this.currentValue = { start, end };
      }
    },
    setDefaultDates(date) {
      this.seeOptions = false;
      if (date.startDate) {
        this.currentValue.start = date.startDate;
        this.currentValue.end = date.endDate;
        this.emitEvent('change');
      }
      this.$emit('select:date-list-option', date.title);
    },
    onOpen() {
      this.seeOptions = false;
      this.emitEvent('open');
    },
    onChange() {
      this.emitEvent('change');
    },
    onClose() {
      this.emitEvent('close');
    },
    onDestroy() {
      this.emitEvent('destroy');
    },
    emitEvent(event) {
      const startDate = stringToDate(this.currentValue.start);
      const endDate = stringToDate(this.currentValue.end);

      this.$emit(event, { value: { startDate, endDate } });
    },
  },
};
</script>

<style>
#date-picker-content {
  min-width: 246px;
}

.vd-picker__input input {
  font-size: 0.875rem !important;
  font-weight: 300;

  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.vd-picker__input input:hover {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity));
}
</style>

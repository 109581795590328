var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isValidDate
        ? _c("span", { staticClass: "truncate w-full text-center" }, [
            _vm._v(" - "),
          ])
        : _c("date-tooltip", {
            staticClass: "block w-full text-center",
            attrs: { date: _vm.value },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Column from '@/model/shared/Column';
import { addDays } from '@/utils/dateTime/addDays';
import { TODAY } from '@/utils/dateTime/today';
import { dateListWithToday } from '@/utils/dateTime/dateListWithToday';

export const textFilterValueGetter = params => {
  const colId = params.column.getColId();
  const value = params.data[colId];
  const valueIsNA = value === 'N/A';
  const parentData = params?.node?.parent?.data;
  const parentHasData = !!parentData && parentData[colId];

  if (valueIsNA && parentHasData) {
    return parentData[colId];
  }

  return params.data[colId];
};
const transformColumnsForAgGrid = column => {
  switch (column.value) {
    case COLUMNS.ID.value:
    case COLUMNS.NAME.value:
    case COLUMNS.OFFICE.value:
    case COLUMNS.PRODUCT_ID.value:
    case COLUMNS.CAMPAIGN_ID.value:
    case COLUMNS.OWNER.value:
    case COLUMNS.ACCOUNT_MANAGER.value:
      column.type = 'textColumn';
      column.sortable = true;
      column.filter = 'agTextColumnFilter';
      column.filterParams = {
        defaultOption: 'contains',
        textMatcher: ({ value, filterText }) => {
          if (filterText == null) {
            return false;
          }

          return value.indexOf(filterText) >= 0;
        },
      };
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case COLUMNS.STATUS.value:
      column.type = 'selectColumn';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      column.minWidth = 120;
      column.maxWidth = 150;
      column.width = 150;
      break;
    case COLUMNS.TYPE.value:
    case COLUMNS.STAGE.value:
      column.type = 'textColumn';
      column.filter = false;
      break;
    case COLUMNS.START.value:
    case COLUMNS.END.value:
    case COLUMNS.CREATED.value:
      column.sortable = true;
      column.filter = false;
      column.type = 'dateColumn';
      break;
    case COLUMNS.ACTIONS.value:
      column.type = 'textColumn';
      column.filter = false;
      column.minWidth = 70;
      column.maxWidth = 70;
      column.width = 70;
      column.resizable = false;
      column.suppressAutoSize = true;
      break;
    case COLUMNS.REPORT_SENT.value:
      column.type = 'textColumn';
      column.filter = false;
      column.minWidth = 120;
      column.maxWidth = 130;
      column.width = 130;
      column.resizable = false;
      column.suppressAutoSize = true;
      break;
    case COLUMNS.TEAM.value:
      column.type = 'textColumn';
      column.sortable = false;
      column.filter = 'agTextColumnFilter';
      column.filterParams = {
        defaultOption: 'contains',
        textMatcher: ({ value, filterText }) => {
          if (filterText == null) {
            return false;
          }

          return value.indexOf(filterText) >= 0;
        },
      };
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    default:
      column.filter = false;
      column.type = 'numberColumn';
      break;
  }

  return { headerName: `${column.visibleTitle()}`, field: column.value, ...column };
};

export const COLUMNS = Object.freeze({
  ID: new Column('ID', 'id').setVisible(false),
  NAME: new Column('NAME', 'name'),
  CAMPAIGN_ID: new Column('CAMPAIGN ID', 'campaignId'),
  PRODUCT_ID: new Column('PRODUCT ID', 'productId'),
  STATUS: new Column('STATUS', 'status'),
  TYPE: new Column('TYPE', 'type'),
  GROUP: new Column('GROUP', 'analyticsType'),
  STAGE: new Column('SF STATUS', 'stage'),
  OFFICE: new Column('OFFICE', 'office'),
  TEAM: new Column('TEAM', 'team'),
  OWNER: new Column('OWNER', 'owner'),
  ACCOUNT_MANAGER: new Column('ACCOUNT MANAGER', 'accountManager'),
  START: new Column('START', 'start'),
  END: new Column('END', 'end'),
  CREATED: new Column('CREATED', 'created'),
  BOOKED: new Column('BOOKED', 'booked'),
  MODEL: new Column('MODEL', 'model'),
  PRICE_EUR: new Column('PRICE EUR', 'priceEur'),
  PRICE_USD: new Column('PRICE USD', 'priceUsd'),
  NET_PRICE_EUR: new Column('NET PRICE EUR', 'netPriceEur'),
  NET_PRICE_USD: new Column('NET PRICE USD', 'netPriceUsd'),
  PRIMARY_KPI: new Column('PRIMARY KPI', 'primaryKpi'),
  SECONDARY_KPI: new Column('SECONDARY KPI', 'secondaryKpi'),
  ACTIONS: new Column('ACTIONS', 'action'),
  REPORT_SENT: new Column('REPORT SENT', 'reportSent'),
});

const productStatus = {
  ACTIVE: 'ACTIVE',
  READY: 'READY',
  PAUSE: 'PAUSE',
  FINISHED: 'FINISHED',
  MATERIALIZE: 'MATERIALIZE',
  PENDING: 'PENDING',
  PREPARED: 'PREPARED',
  SEC: 'SEC',
  AGENCY: 'AGENCY',
  INVOICE_SENT: 'INVOICE_SENT',
};

export const rowClassRules = {
  'product-active': params => {
    return params.data?.status === productStatus.ACTIVE || params.node.aggData?.status === productStatus.ACTIVE;
  },
  'product-pending': params => {
    return params.data?.status === productStatus.PENDING || params.node.aggData?.status === productStatus.PENDING;
  },
  'product-prepared': params => {
    return params.data?.status === productStatus.PREPARED || params.node.aggData?.status === productStatus.PREPARED;
  },
  'product-pause': params => {
    return params.data?.status === productStatus.PAUSE || params.node.aggData?.status === productStatus.PAUSE;
  },
  'product-materials': params => {
    return (
      params.data?.status === productStatus.MATERIALIZE || params.node.aggData?.status === productStatus.MATERIALIZE
    );
  },
  'product-finished': params => {
    return params.data?.status === productStatus.FINISHED || params.node.aggData?.status === productStatus.FINISHED;
  },
};

const CONFIG = {
  COMMON_ID_VIEW: 'third-party-campaign-manager',
  defaultColDef: {
    sortable: false,
    unSortIcon: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
    minWidth: 50,
    width: 100,
    flex: 1,
    cellRenderer: 'AnalyticsCellOrchestrator',
    suppressMenu: true,
  },
  defaultColumns: [
    COLUMNS.CAMPAIGN_ID,
    COLUMNS.PRODUCT_ID,
    COLUMNS.STATUS,
    COLUMNS.TYPE,
    COLUMNS.GROUP,
    COLUMNS.STAGE,
    COLUMNS.OFFICE,
    COLUMNS.OWNER,
    COLUMNS.ACCOUNT_MANAGER,
    COLUMNS.START,
    COLUMNS.END,
    COLUMNS.CREATED,
    COLUMNS.BOOKED,
    COLUMNS.MODEL,
    COLUMNS.PRICE_EUR,
    COLUMNS.PRICE_USD,
    COLUMNS.PRIMARY_KPI,
    COLUMNS.SECONDARY_KPI,
    COLUMNS.REPORT_SENT,
    COLUMNS.ACTIONS,
  ],
  columnTypes: {
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'greaterThanOrEqual', 'lessThanOrEqual', 'inRange'],
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          return cellDate > filterLocalDateAtMidnight ? 1 : 0;
        },
      },
    },
    textColumn: {
      filter: 'agTextColumnFilter',
      filterValueGetter: textFilterValueGetter,
      filterParams: {
        textMatcher: ({ node, filterOption, value, filterText }) => {
          if (node.level !== 0) {
            return true;
          }
          if (filterText == null) {
            return false;
          }
          switch (filterOption) {
            case 'contains':
              return value.indexOf(filterText) >= 0;
            case 'notContains':
              return value.indexOf(filterText) < 0;
            case 'equals':
              return value === filterText;
            case 'notEqual':
              return value !== filterText;
            case 'startsWith':
              return value.indexOf(filterText) === 0;
            case 'endsWith':
              // eslint-disable-next-line no-case-declarations
              const index = value.lastIndexOf(filterText);
              return index >= 0 && index === value.length - filterText.length;
            default:
              // should never happen
              // console.warn(`invalid filter type ${filter}`);
              return false;
          }
        },
      },
    },
    selectColumn: {
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      floatingFilter: true,
      suppressMenu: false,
      filterParams: {
        suppressMiniFilter: false,
        comparator: (a, b) => {
          const valA = parseInt(a);
          const valB = parseInt(b);
          if (valA === valB) return 0;
          return valA > valB ? 1 : -1;
        },
      },
    },
    numberColumn: {
      filter: 'agNumberColumnFilter',
      comparator: (a, b) => {
        const safeA = a === 'N/A' ? 0 : a;
        const safeB = b === 'N/A' ? 0 : b;
        return safeA - safeB;
      },
      filterParams: {
        numberParser: text => (text == null ? null : parseFloat(text.replace(',', '.'))),
      },
    },
    currencyColumn: {
      filter: 'agNumberColumnFilter',
      comparator: (a, b) => (a || 0) - (b || 0),
      filterValueGetter: params => {
        const colId = params.column.colId;
        const value = params.data[colId] || null;

        return value?.value;
      },
      filterParams: {
        numberParser: text => (text == null ? null : parseFloat(text.replace(',', '.'))),
      },
    },
  },
  exportParams: {
    sheetName: 'Campaign Manager',
    processCellCallback: params => {
      if (!params.value) return '-';
      return params.value;
    },
  },
  // Config For date picker
  dateListWithToday,
  CUSTOM_OPTION: 'Custom',
  defaultDate: { startDate: addDays(TODAY, -30), endDate: TODAY },
  limitMaxDate: TODAY,
  // Params for filters
  opportunityTypes: {
    CAMPAIGN_LIVE: 'Campaign Live',
    IO_SIGNED: 'Io Signed',
  },
  productStatus: [
    { id: 'ACTIVE', value: 'Active' },
    { id: 'READY', value: 'Ready' },
    { id: 'PAUSE', value: 'Pause' },
    { id: 'FINISHED', value: 'Finished' },
    { id: 'MATERIALIZE', value: 'Materialize' },
    { id: 'PENDING', value: 'Pending' },
    { id: 'SEC', value: 'SEC' },
    { id: 'AGENCY', value: 'Agency' },
    { id: 'INVOICE_SENT', value: 'Invoice Sent' },
  ],
  campaignManagerTypes: {
    CAMPAIGN_MANAGER_TODAY: 'CAMPAIGN_MANAGER_TODAY',
    CAMPAIGN_MANAGER_TOMORROW: 'CAMPAIGN_MANAGER_TOMORROW',
    CAMPAIGN_MANAGER_STARTING: 'CAMPAIGN_MANAGER_STARTING',
  },
  rowClassRules,
};

export const agGridColumns = CONFIG.defaultColumns.map(transformColumnsForAgGrid);
export default CONFIG;

const CONFIG = {
  date: { start: null, end: null },
  name: 'datepickerV2',
  allowedDates: date => parseInt(date, 10) % 2 === 0,
  allowedOverflow: true,
  attach: false,
  buttonCancel: null,
  buttonValidate: null,
  clearable: false,
  color: '#ed8936',
  contentClass: null,
  disabled: false,
  format: 'DD/MM/YYYY',
  formatHeader: 'DD MMMM YYYY',
  fullscreenMobile: false,
  mobileBreakpoint: 576,
  inline: false,
  locale: { lang: 'en' },
  minDate: null,
  maxDate: null,
  noCalendarIcon: false,
  noHeader: false,
  origin: null,
  placeholder: 'DD/MM/YYYY to DD/MM/YYYY',
  range: false,
  rangeHeaderText: 'From %d To %d',
  rangeInputText: '%d to %d',
  rangePresets: null,
  rtl: false,
  tabindex: 0,
  type: 'date',
  validate: false,
  visible: false,
  visibleYearsNumber: 5,
  zIndex: 1002,
};

export default CONFIG;
